import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { fetchPostalCodes } from "../../redux/Actions";
import { Link, useNavigate } from "react-router-dom";
import GuestLogin from "../guestLogin";
import axios from "axios";
import { clearCart, clearCartCount, clearSplitPrice, setOrderType } from "./../../redux/Actions/cartCountAction";
import { setValue } from "../../utility";
import { clearUserProductCategories } from "../../redux/Actions/productCategoriesAction";
import { fetchGetShopByPinCode } from "../../redux/Actions/checkoutPageActions";
import { DebounceInput } from "react-debounce-input";
const base_url = process.env.REACT_APP_BACKEND_URL;
const client_base_url=process.env.REACT_APP_BASE_URL;

const QuickOrderPopup: React.FC<{ close: any }> = ({ close }) => {
  const [postalCodeList, setPostalCodeList] = useState([]);
  const [postalCodeValue, setPostalCodeValue] = useState("");
  const [guestLoginPopup, setGuestLoginPopup] = useState(false);
  const [userName, setUserName] = useState("");
  const dispatch = useDispatch<any>();
  const state: any = useSelector((state) => state);
  const navigate = useNavigate();
  const [postalCodesSearch, setPostalCodesSearch] = useState<any | null>({});

  useEffect(() => {
    if (state) {
      setPostalCodeList(state.postalCodeList);
    }
  }, [state]);


  // useEffect(() => {

  //     if (state) {

  //         const {data = {data: {}}} = state.userDetails;

  //         if (data) {
  //             const {data: {user_info = {}}} = data;
  //             const {name = ''} = user_info;
  //             

  //             setUserName(name);

  //         }
  //     }
  // }, [state])

  const handleChange = (e: any) => {
    // setPostalCodeList(e.target.value);
    const { value } = e.target;
    const updateValue = value.replace(/\s/g,'')
    if (value.length > 0) {
      dispatch(fetchPostalCodes(updateValue));
    }
    setPostalCodeValue(updateValue);
  };
  // const onSearch = (searchTerm: any) => {
  //     // setPostalCodeList(searchTerm);
  //     setPostalCodeValue(searchTerm);
  //     dispatch(fetchPostalCodes(searchTerm));

  //     
  // };

  const onSearch = (searchTerm: any) => {
    // setPostalCodeList(searchTerm);
    setPostalCodeValue(searchTerm);
    setValue("postalCode",searchTerm.toUpperCase())
    // dispatch(fetchPostalCodes(searchTerm));
  };

  // const handleOrder = () =>{
  //     navigate("/guestLogin");
  // }

  const handleQuickOrder = async (e: any) => {
    e.preventDefault();

    let userName = JSON.parse(localStorage.getItem("userDetails")!);
    const isValid = postalCodeList.some(
      (item: any) => item.postcode === postalCodeValue.toUpperCase()
    );

    setValue("postalCode",postalCodeValue.toUpperCase())
    const results = await dispatch(fetchGetShopByPinCode(JSON.stringify(postalCodeValue)));
    
    if (!results) {
        window.location.href = "/areaNotCovered";
        return;
    }

    if (results) {
      dispatch(setOrderType(1));
      setValue("orderType",1);
      localStorage.removeItem('cartInformationData');
      dispatch(clearCartCount());
      dispatch(clearCart());
      dispatch(clearUserProductCategories());
      // dispatch(clearSplitPrice());
      userName == null ? navigate("/guestLogin") : navigate("/checkout");
      close();
    } else {
      localStorage.removeItem('cartInformationData');
      dispatch(clearCartCount());
      dispatch(clearCart());
      dispatch(clearUserProductCategories());
      // dispatch(clearSplitPrice());
      navigate("/areaNotCovered");
    }
  };

  const handleQuickBookClose = () => {
    close();
  };

  // const handleQuickOrder = (event: any) => {
  //     event.preventDefault();
  //       const headers = {
  //         'Accept': 'application/json'

  //         }
  //         setPostalCodesSearch({result:null})
  //           axios.get(`${base_url}/api/get_shop_by_pincode?pincode=${ postalCodeValue }`,{
  //           headers: headers

  //     }).then(e=>{setPostalCodesSearch({result:e.data})

  // let lengthvar=e.data.Response.response_message;
  // const userinfo = JSON.parse(localStorage.getItem("userDetails")!);
  // if (lengthvar=="Sorry, we didn't cover your postal code yet! Please check for another postal code")
  // {   event.preventDefault();
  //     close();
  //     navigate("/areaNotCovered");

  //     return false
  // }
  // else if (userinfo==="")
  // {
  //     close();
  //     navigate("/guestLogin")
  // }
  // else if (userinfo !=="")
  // {
  //     close();
  //     navigate("/checkout")
  // }

  // })
  // // const userinfo = JSON.parse(localStorage.getItem("userDetails")!);
  // // if (userinfo) {
  // //     userinfo !== "" ?   navigate("/checkout") : navigate('/guestLogin')

  // // } else {
  // //     navigate("/areaNotCovered");
  // // }
  // }

  //  alert(state)
  return (
    <div>
        <div className="modal-header">
          <h3>Please enter your postal code</h3>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleQuickBookClose}></button>
        </div>
          <div className="modal-body">            
            <form id="quick-order-form">
              <div className="postcodeform postcodeform-modal">
              {/* <input
                type="text"
                name="postal_code"
                id="postal_code"
                value={postalCodeValue}
                placeholder="Enter your postal code"
                onChange={handleChange}
                autoComplete="off"
              /> */}
              <DebounceInput debounceTimeout={500} value={postalCodeValue} placeholder="Enter Postcode" onChange={handleChange} autoComplete="off" />
              <div className="dropdown">
                {postalCodeList
                  .filter((item: any) => {
                    const searchTerm = postalCodeValue.toLowerCase();
                    const mainValue = item.postcode.toLowerCase();

                    return (
                      searchTerm &&
                      mainValue.startsWith(searchTerm) &&
                      mainValue !== searchTerm
                    );
                  })
                  .slice(0, 10)
                  .map((item: any) => (
                    <div
                      onClick={() => onSearch(item.postcode)}
                      className="dropdown-row"
                      key={item.postcode}
                    >
                      <i className="fas fa-map-marker-alt"></i>{item.postcode}
                    </div>
                  ))}
              </div>
              </div>
              <input
                type="button"
                name=""
                id=""
                data-bs-toggle="modal"
                value="Continue to order"
                onClick={handleQuickOrder}
              ></input>
              {/* <button  className="btn hover-btn" id="book_now_btn" type="submit" onClick={handleQuickOrder}>Continue to order</button> */}
              <input
                type="hidden"
                name="order_type"
                id="order_type"
                value="1"
              />
              <input
                type="hidden"
                name="_token"
                value="3kG3W86Tk4OJE3wsaVIvIiCGDFbW3jWfdIIGaft3"
              />
            </form>
          
            </div>
      {/* {guestLoginPopup && <GuestLogin />} */}
    </div>
  );
};

export default QuickOrderPopup;
