import axios from '../../config';
export const SET_SPLITPRICE_DETAILS = "SET_SPLITPRICE_DETAILS";
export const CLEAR_SPLITPRICE_DETAILS = "CLEAR_SPLITPRICE_DETAILS";

export const setSplitPriceDetails = (payload: any) => ({
    type: SET_SPLITPRICE_DETAILS,
    payload
})
export const fetchSplitPriceDetails = (payload:any) => async (dispatch: any) => {
    const base_url = process.env.REACT_APP_BACKEND_URL;
    
    try {

        const bodyFormData = new FormData();
        // bodyFormData.append('limit', limit);
        // // formData.getAll('limit')
        // bodyFormData.append('page', page);
        // bodyFormData.append('sort', sort);
        // bodyFormData.append('order_status', order_status);


        // res.header('Access-Control-Allow-Methods', 'GET, POST');
        // const res = JSON.parse(localStorage.getItem("userDetails") || '');
        // const token = res.Response.token;
       
        const token = localStorage.getItem("token");
        
        const response = await axios({
            method: "get",
            url: `${base_url}/api/get_price_split_details?total_price_amount=${payload?.finalTotalPrice}&offer_id=${payload?.offerId}&paid_pickup=${payload?.paidPickupSelected ?? 'false'}`,
            data: bodyFormData,
            headers: {
                 "Accept": "application/json", 
                 "Authorization": "Bearer " + token,
                 'Access-Control-Allow-Methods': 'GET, POST' },
        })
        if (response) {
            dispatch(setSplitPriceDetails({...response.data?.data, itemsCount: payload?.itemsCount}))
        }
    } catch (error) {
        
    }
    // axios
    //   .post(apiBase + "/auth/logout/", null, tokenConfig(getState))
    //   .then(res => {
    //     dispatch({
    //       type: LOGOUT_SUCCESS
    //     });
    //   })
    //   .catch((err) => {
    //     dispatch(returnErrors(err.response.data, err.response.status));
    //   });
};